import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _20910e20 = () => interopDefault(import('..\\pages\\configuration\\index.vue' /* webpackChunkName: "pages/configuration/index" */))
const _158cb292 = () => interopDefault(import('..\\pages\\dashboard\\index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _2fe375a4 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _9c942f76 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _5bcfae44 = () => interopDefault(import('..\\pages\\logout.vue' /* webpackChunkName: "pages/logout" */))
const _6d501ef9 = () => interopDefault(import('..\\pages\\payouts\\index.vue' /* webpackChunkName: "pages/payouts/index" */))
const _1216d71a = () => interopDefault(import('..\\pages\\sales\\index.vue' /* webpackChunkName: "pages/sales/index" */))
const _65e98b20 = () => interopDefault(import('..\\pages\\spiffplans\\index.vue' /* webpackChunkName: "pages/spiffplans/index" */))
const _533b58e7 = () => interopDefault(import('..\\pages\\cms\\components\\index.vue' /* webpackChunkName: "pages/cms/components/index" */))
const _5857af23 = () => interopDefault(import('..\\pages\\cms\\contents\\index.vue' /* webpackChunkName: "pages/cms/contents/index" */))
const _0fd99a34 = () => interopDefault(import('..\\pages\\cms\\components\\_componentId.vue' /* webpackChunkName: "pages/cms/components/_componentId" */))
const _0ba72cf4 = () => interopDefault(import('..\\pages\\cms\\contents\\_contentId.vue' /* webpackChunkName: "pages/cms/contents/_contentId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/configuration",
    component: _20910e20,
    name: "configuration___en"
  }, {
    path: "/dashboard",
    component: _158cb292,
    name: "dashboard___en"
  }, {
    path: "/fr",
    component: _2fe375a4,
    name: "index___fr"
  }, {
    path: "/login",
    component: _9c942f76,
    name: "login___en"
  }, {
    path: "/logout",
    component: _5bcfae44,
    name: "logout___en"
  }, {
    path: "/payouts",
    component: _6d501ef9,
    name: "payouts___en"
  }, {
    path: "/sales",
    component: _1216d71a,
    name: "sales___en"
  }, {
    path: "/spiffplans",
    component: _65e98b20,
    name: "spiffplans___en"
  }, {
    path: "/cms/components",
    component: _533b58e7,
    name: "cms-components___en"
  }, {
    path: "/cms/contents",
    component: _5857af23,
    name: "cms-contents___en"
  }, {
    path: "/fr/configuration",
    component: _20910e20,
    name: "configuration___fr"
  }, {
    path: "/fr/dashboard",
    component: _158cb292,
    name: "dashboard___fr"
  }, {
    path: "/fr/login",
    component: _9c942f76,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _5bcfae44,
    name: "logout___fr"
  }, {
    path: "/fr/payouts",
    component: _6d501ef9,
    name: "payouts___fr"
  }, {
    path: "/fr/sales",
    component: _1216d71a,
    name: "sales___fr"
  }, {
    path: "/fr/spiffplans",
    component: _65e98b20,
    name: "spiffplans___fr"
  }, {
    path: "/fr/cms/components",
    component: _533b58e7,
    name: "cms-components___fr"
  }, {
    path: "/fr/cms/contents",
    component: _5857af23,
    name: "cms-contents___fr"
  }, {
    path: "/fr/cms/components/:componentId",
    component: _0fd99a34,
    name: "cms-components-componentId___fr"
  }, {
    path: "/fr/cms/contents/:contentId",
    component: _0ba72cf4,
    name: "cms-contents-contentId___fr"
  }, {
    path: "/cms/components/:componentId",
    component: _0fd99a34,
    name: "cms-components-componentId___en"
  }, {
    path: "/cms/contents/:contentId",
    component: _0ba72cf4,
    name: "cms-contents-contentId___en"
  }, {
    path: "/",
    component: _2fe375a4,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
