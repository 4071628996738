
const initState = () => ({
  plans: [],
  currentJoinedPlans: []
})

export const state = initState;

export const mutations = {
  setPlans(state, plans) {
    if(state.plans.length) {
      plans.forEach(plan=>{
        state.plans.push(plan)
      })
    }
    else {
    state.plans = plans;
    }
  },
  setCurrentJoinedPlans(state, plans) {
    state.currentJoinedPlans = plans
  },
  removePlanFromAvailable(state, plan) {
    state.plans = state.plans.filter(lPlan=>lPlan.id !== plan.id)
  },
  resetPlans(state) {
    state.plans = []
    state.currentJoinedPlans = []
  }
}

export const actions = {
  fetchPlans({commit}, page) {
   return this.$axios.$get(`spiffPlans/${page}`).then(plans=>{
      commit('setPlans', plans)
    })
  },

  fetchAvailabilePlans({commit}, payload) {
    let formData = new FormData();
    formData.set("page",payload.page)
    if(payload.userId) {
      formData.set("userId",payload.userId)
    }
    return this.$axios.$post(`spiffPlans/availibileForUser`, formData).then(plans=> commit('setPlans',plans.value))
  },

  fetchItemsToSellForPlan({commit},planId) {
    return this.$axios.$get(`spiffPlans/ItemsToSell/${planId}`)
  },
  addUserToPlan({commit}, payload) {
    let formData = new FormData();
    formData.set("userId",payload.userId)
    return this.$axios.$post(`spiffPlans/JoinPlan/${payload.plan.id}`, formData)
  },
  fetchPlansPerUser({commit}, payload) {
    let formData = new FormData();
    if(payload && payload.page) {
      formData.set("page",payload.page)
    }
    if(payload && payload.userId) {
      formData.set("userId",payload.userId)
    }
    return this.$axios.$post(`spiffPlans/myPlans`, formData).then(plans=> commit('setCurrentJoinedPlans',plans.value))
  },
  removePlanFromAvailable({commit},plan) {
    commit('removePlanFromAvailable',plan)
  },
  getAllSpiffUsers({commit}) {
    return this.$axios.$get(`spiffPlans/members`)
  },
  resetTables({commit}) {
    commit('resetPlans')
  }
}
