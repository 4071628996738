export const NuxtDynamic = () => import('../..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const CommonColumnUserSettingDialog = () => import('../..\\components\\common\\column-user-setting-dialog.vue' /* webpackChunkName: "components/common-column-user-setting-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonConfirm = () => import('../..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomNumberInput = () => import('../..\\components\\common\\custom-number-input.vue' /* webpackChunkName: "components/common-custom-number-input" */).then(c => wrapFunctional(c.default || c))
export const CommonExpandingFilterButton = () => import('../..\\components\\common\\expanding-filter-button.vue' /* webpackChunkName: "components/common-expanding-filter-button" */).then(c => wrapFunctional(c.default || c))
export const CommonForgotPasswordDialog = () => import('../..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonInfoBox = () => import('../..\\components\\common\\info-box.vue' /* webpackChunkName: "components/common-info-box" */).then(c => wrapFunctional(c.default || c))
export const CommonLeftMenu = () => import('../..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonLoginCard = () => import('../..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => wrapFunctional(c.default || c))
export const CommonNavigationBars = () => import('../..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => wrapFunctional(c.default || c))
export const CommonPrimaryButton = () => import('../..\\components\\common\\primary-button.vue' /* webpackChunkName: "components/common-primary-button" */).then(c => wrapFunctional(c.default || c))
export const CommonRegisterCard = () => import('../..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => wrapFunctional(c.default || c))
export const CommonSecondaryButton = () => import('../..\\components\\common\\secondary-button.vue' /* webpackChunkName: "components/common-secondary-button" */).then(c => wrapFunctional(c.default || c))
export const CommonSwitchUser = () => import('../..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentAdd = () => import('../..\\components\\cms\\component-add.vue' /* webpackChunkName: "components/cms-component-add" */).then(c => wrapFunctional(c.default || c))
export const CmsComponentFieldEditDialog = () => import('../..\\components\\cms\\component-field-edit-dialog.vue' /* webpackChunkName: "components/cms-component-field-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const CmsContentAdd = () => import('../..\\components\\cms\\content-add.vue' /* webpackChunkName: "components/cms-content-add" */).then(c => wrapFunctional(c.default || c))
export const CmsContentField = () => import('../..\\components\\cms\\content-field.vue' /* webpackChunkName: "components/cms-content-field" */).then(c => wrapFunctional(c.default || c))
export const CmsFileInfo = () => import('../..\\components\\cms\\file-info.vue' /* webpackChunkName: "components/cms-file-info" */).then(c => wrapFunctional(c.default || c))
export const CmsImageCropper = () => import('../..\\components\\cms\\image-cropper.vue' /* webpackChunkName: "components/cms-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const CmsSectionField = () => import('../..\\components\\cms\\section-field.vue' /* webpackChunkName: "components/cms-section-field" */).then(c => wrapFunctional(c.default || c))
export const ConfigurationFieldRows = () => import('../..\\components\\configuration\\field-rows.vue' /* webpackChunkName: "components/configuration-field-rows" */).then(c => wrapFunctional(c.default || c))
export const ConfigurationFieldSettingDialog = () => import('../..\\components\\configuration\\field-setting-dialog.vue' /* webpackChunkName: "components/configuration-field-setting-dialog" */).then(c => wrapFunctional(c.default || c))
export const ConfigurationGroupSettingsDialog = () => import('../..\\components\\configuration\\group-settings-dialog.vue' /* webpackChunkName: "components/configuration-group-settings-dialog" */).then(c => wrapFunctional(c.default || c))
export const ConfigurationModules = () => import('../..\\components\\configuration\\modules.vue' /* webpackChunkName: "components/configuration-modules" */).then(c => wrapFunctional(c.default || c))
export const ConfigurationRoleRows = () => import('../..\\components\\configuration\\role-rows.vue' /* webpackChunkName: "components/configuration-role-rows" */).then(c => wrapFunctional(c.default || c))
export const ConfigurationRoleSettingDialog = () => import('../..\\components\\configuration\\role-setting-dialog.vue' /* webpackChunkName: "components/configuration-role-setting-dialog" */).then(c => wrapFunctional(c.default || c))
export const SharedAttributeFields = () => import('../..\\components\\shared\\attribute-fields.vue' /* webpackChunkName: "components/shared-attribute-fields" */).then(c => wrapFunctional(c.default || c))
export const SharedCarouselTest = () => import('../..\\components\\shared\\carousel-test.vue' /* webpackChunkName: "components/shared-carousel-test" */).then(c => wrapFunctional(c.default || c))
export const SharedCloseBtn = () => import('../..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomButton = () => import('../..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomCombo = () => import('../..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => wrapFunctional(c.default || c))
export const SharedCustomSwitch = () => import('../..\\components\\shared\\custom-switch.vue' /* webpackChunkName: "components/shared-custom-switch" */).then(c => wrapFunctional(c.default || c))
export const SharedDarkButton = () => import('../..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => wrapFunctional(c.default || c))
export const SharedFilterButton = () => import('../..\\components\\shared\\filter-button.vue' /* webpackChunkName: "components/shared-filter-button" */).then(c => wrapFunctional(c.default || c))
export const SharedFilterCard = () => import('../..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => wrapFunctional(c.default || c))
export const SharedGallery = () => import('../..\\components\\shared\\gallery.vue' /* webpackChunkName: "components/shared-gallery" */).then(c => wrapFunctional(c.default || c))
export const SharedImageCombo = () => import('../..\\components\\shared\\image-combo.vue' /* webpackChunkName: "components/shared-image-combo" */).then(c => wrapFunctional(c.default || c))
export const SharedImageUploadCombo = () => import('../..\\components\\shared\\image-upload-combo.vue' /* webpackChunkName: "components/shared-image-upload-combo" */).then(c => wrapFunctional(c.default || c))
export const SharedImageUploaderField = () => import('../..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => wrapFunctional(c.default || c))
export const SharedImageUploader = () => import('../..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => wrapFunctional(c.default || c))
export const SharedInfoFields = () => import('../..\\components\\shared\\info-fields.vue' /* webpackChunkName: "components/shared-info-fields" */).then(c => wrapFunctional(c.default || c))
export const SharedLabeledControlFields = () => import('../..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => wrapFunctional(c.default || c))
export const SharedLabeledControlLayout = () => import('../..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => wrapFunctional(c.default || c))
export const SharedMemberSelectDialog = () => import('../..\\components\\shared\\member-select-dialog.vue' /* webpackChunkName: "components/shared-member-select-dialog" */).then(c => wrapFunctional(c.default || c))
export const SharedMenuDropdownButton = () => import('../..\\components\\shared\\menu-dropdown-button.vue' /* webpackChunkName: "components/shared-menu-dropdown-button" */).then(c => wrapFunctional(c.default || c))
export const SharedNameValueField = () => import('../..\\components\\shared\\name-value-field.vue' /* webpackChunkName: "components/shared-name-value-field" */).then(c => wrapFunctional(c.default || c))
export const SharedPrimarySubheader = () => import('../..\\components\\shared\\primary-subheader.vue' /* webpackChunkName: "components/shared-primary-subheader" */).then(c => wrapFunctional(c.default || c))
export const SharedPrimaryTitle = () => import('../..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => wrapFunctional(c.default || c))
export const SharedRadioCombo = () => import('../..\\components\\shared\\radio-combo.vue' /* webpackChunkName: "components/shared-radio-combo" */).then(c => wrapFunctional(c.default || c))
export const SharedSecondaryButton = () => import('../..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => wrapFunctional(c.default || c))
export const SharedSimpleInfoFields = () => import('../..\\components\\shared\\simple-info-fields.vue' /* webpackChunkName: "components/shared-simple-info-fields" */).then(c => wrapFunctional(c.default || c))
export const SharedSimpleNameValueField = () => import('../..\\components\\shared\\simple-name-value-field.vue' /* webpackChunkName: "components/shared-simple-name-value-field" */).then(c => wrapFunctional(c.default || c))
export const SalesFilterCardDatePicker = () => import('../..\\components\\sales\\filter-card-date-picker.vue' /* webpackChunkName: "components/sales-filter-card-date-picker" */).then(c => wrapFunctional(c.default || c))
export const SalesViewReceiptDialog = () => import('../..\\components\\sales\\view-receipt-dialog.vue' /* webpackChunkName: "components/sales-view-receipt-dialog" */).then(c => wrapFunctional(c.default || c))
export const SpiffplansCustomTableWithHeader = () => import('../..\\components\\spiffplans\\custom-table-with-header.vue' /* webpackChunkName: "components/spiffplans-custom-table-with-header" */).then(c => wrapFunctional(c.default || c))
export const SpiffplansItemsSoldDialog = () => import('../..\\components\\spiffplans\\items-sold-dialog.vue' /* webpackChunkName: "components/spiffplans-items-sold-dialog" */).then(c => wrapFunctional(c.default || c))
export const SpiffplansReceiptCamera = () => import('../..\\components\\spiffplans\\receipt-camera.vue' /* webpackChunkName: "components/spiffplans-receipt-camera" */).then(c => wrapFunctional(c.default || c))
export const SpiffplansSaleReceiptForm = () => import('../..\\components\\spiffplans\\sale-receipt-form.vue' /* webpackChunkName: "components/spiffplans-sale-receipt-form" */).then(c => wrapFunctional(c.default || c))
export const CommonAdminSettingsAdmColumnsAutoComplete = () => import('../..\\components\\common\\admin-settings\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/common-admin-settings-adm-columns-auto-complete" */).then(c => wrapFunctional(c.default || c))
export const CommonAdminSettingsGlobalSettings = () => import('../..\\components\\common\\admin-settings\\global-settings.vue' /* webpackChunkName: "components/common-admin-settings-global-settings" */).then(c => wrapFunctional(c.default || c))
export const CommonMyProfileAddCardFields = () => import('../..\\components\\common\\my-profile\\add-card-fields.vue' /* webpackChunkName: "components/common-my-profile-add-card-fields" */).then(c => wrapFunctional(c.default || c))
export const CommonMyProfileCreditCardInfoCard = () => import('../..\\components\\common\\my-profile\\credit-card-info-card.vue' /* webpackChunkName: "components/common-my-profile-credit-card-info-card" */).then(c => wrapFunctional(c.default || c))
export const CommonMyProfileEditFormCard = () => import('../..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => wrapFunctional(c.default || c))
export const CommonMyProfileGeneralInfoCard = () => import('../..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => wrapFunctional(c.default || c))
export const CommonMyProfilePasswordChangeCard = () => import('../..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
