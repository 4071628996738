import {v4 as v4uuid} from 'uuid'

export const applyDrag = (arr, dragResult) => {
  const {removedIndex, addedIndex, payload} = dragResult

  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}
export const extractRootDomain = (url) => {
  const hostRegex = new RegExp('^(?<host>(http[s]?|ftp):\\/?\\/?[^:\\/\\s]+)')
  const regexExecResult = hostRegex.exec(url)
  const {
    groups: {host},
  } = regexExecResult

  return host
}

export const slugify = (input, separator = '-') => {
  return (input || '')
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036F]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator)
}

export const getShoppingCartSID = (vm) => {
  let shoppingCartSID = localStorage.getItem('shoppingCartSID')
  if (!shoppingCartSID) {
    shoppingCartSID = v4uuid()
    localStorage.setItem('shoppingCartSID', shoppingCartSID)
  }
  return shoppingCartSID
}

export const resetShoppingCartSID = () => {
  localStorage.removeItem('shoppingCartSID')
}
