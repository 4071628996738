const initState = () => ({
  rememberedUser: null
})

export const state = initState

export const mutations = {
  saveUser(state, userInfo) {
    state.rememberedUser = userInfo;
  },
  removeSavedUser(state) {
    state.rememberedUser = null;
  }
}

export const getters = {
  getSavedUserInfo(state) {
    return state.rememberedUser;
  }
}

export const actions = {
  saveUser({commit}, userInfo) {
    commit('saveUser', userInfo)
  },
  removeSavedUser({commit}) {
    commit('removeSavedUser')
  }
}
