const initState = () => ({
  fields: {},
  adminSettings: {},
})

export const state = initState

export const getters = {
  fieldsByModuleCode: (state) => (moduleCode) => state.fields[moduleCode],
  adminSettingByCode: (state) => (moduleCode) =>
    state.adminSettings[moduleCode],
}

export const mutations = {
  setFields(state, {fields}) {
    state.fields = fields
  },
  setAdminSettings(state, {adminSettings}) {
    state.adminSettings = adminSettings
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  fetchFields({commit}) {
    console.log('start getting fields')
    return this.$axios.$get('/modules/cp/fields').then((fields) => {
      const fieldsObj = {}
      for (const field of fields) {
        fieldsObj[field.moduleCode] = field.fields
      }
      console.log('got fields')
      commit('setFields', {fields: fieldsObj})
    })
  },
  fetchAdminSettings({commit}) {
    console.log('start getting admin settings')
    return this.$axios.$get('/adminSettings').then((adminSettings) => {
      const fieldsObj = {}
      for (const adminSetting of adminSettings) {
        fieldsObj[adminSetting.code] = adminSetting
      }
      console.log('got admin settings')
      commit('setAdminSettings', {adminSettings: fieldsObj})
    })
  },
  fetchAdminSettingByCode({commit}, code) {
    return this.$axios.$get('/admin-settings/cp/settings/' + code)
  },
}
