const initState= () => ({
  currentViewedMemberID: null,
  allMembers: []
})
export const state = initState

export const mutations = {
  setSelectedMemberID(state, id) {
    state.currentViewedMemberID = id
  },
  setAllMembers(state, members) {
    state.allMembers = members
  }
}

export const actions = {
  setSelectedMemberID({commit}, id) {
    commit('setSelectedMemberID', id)
  },
  getAllSpiffUsers({commit}) {
    return this.$axios.$get(`spiffPlans/members`).then(members=>{commit('setAllMembers',members)})
  },
}
