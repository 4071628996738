
const initState = () => ({
  salesTableItems: []
})

export const state = initState

export const mutations = {
  setSales(state, sales) {
    state.salesTableItems = sales.map(sale=>sale = {
      planName: sale.planName,
      itemName: sale.itemName ? sale.itemName : "COMPREHENSIVE PLAN",
      salePrice: sale.salesPrice,
      prize: sale.prize,
      uploadReceipt: sale.code,
      paymentStatus: sale.statusName,
      saleID: sale.saleID,
      saleDate: sale.payoutDate,
      statusCode: sale.statusCode
    })
  },
  filterSales(state, filters) {
    if(filters.status !== "All statuses") {
      if(filters.status === "Submitted") {
        state.salesTableItems = state.salesTableItems.filter(sale=>sale.paymentStatus === filters.status || sale.statusCode === "WAITING_FOR_APPROVAL")
      }
      else {
        state.salesTableItems = state.salesTableItems.filter(sale=>sale.paymentStatus === filters.status)
      }
    }
    if(filters.fromDate && filters.fromDate !== "Invalid date") {
      state.salesTableItems = state.salesTableItems.filter(sale=> {
        let d1 = new Date(filters.fromDate)
        let d2 = new Date(sale.saleDate)
        return d1 <= d2
        }
      )
    }
    if(filters.toDateFilter && filters.toDateFilter !== "Invalid date") {
      state.salesTableItems = state.salesTableItems.filter(sale=>{
        let d1 = new Date(filters.toDateFilter)
        let d2 = new Date(sale.saleDate)
        return d1 >= d2
      })
    }
  }
}

export const actions = {
  fetchSales({commit}, userID) {
    return this.$axios.$get(`spiffSales/${userID}`).then(sales=>{
      commit('setSales', sales.value)
    });
  },
  async updateReceiptPicture({commit}, {fileInfoID, saleID}) {
    let formData = new FormData()
    formData.set("FileInfoID", fileInfoID)
    formData.set('saleID', saleID)
    await this.$axios.$post('spiffSales/updateSaleReceipt', formData).then(response=>{
      if(response.value === true) {
        this.$toast.success("Receipt image updated!")
        return true
      } else {
        this.$toast.error("Error while updating receipt image!")
        return false
      }
    })
  },
  filterSales({commit, dispatch}, filters) {
    commit('filterSales', filters)
  }
}
