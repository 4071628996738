const initState = () => ({
  inProgressTableHeaders: [],
  inProgressTableItems: [],
  myPayoutsTableHeaders: [],
  myPayoutsTableItems: []
})

export const state = initState


export const mutations = {
  setTableHeaders(state, headers) {
    state.inProgressTableHeaders = headers[0]
    state.myPayoutsTableHeaders = headers[1]
  },
  setInProgressHeaders(state, headers) {
    state.inProgressTableHeaders = headers
  },
  setMyPayoutsHeaders(state, headers) {
    state.myPayoutsTableHeaders = headers
  },
  setInProgressItems(state, items) {
    let lPayouts = items.map(i=>i={
      name: i.planName,
      itemType: i.itemName,
      sku: i.code,
      price: i.salesPrice,
      prize: i.prize,
      status: i.statusID,
      statusName: i.statusName
    })
    state.inProgressTableItems = lPayouts;
  },
  setMyPayoutsTableItems(state, items) {
    let lPayouts = items.map(i=>i={
      name: i.planName,
      itemType: i.itemName,
      sku: i.code,
      price: i.salesPrice,
      prize: i.prize,
      status: i.statusID,
      statusName: i.statusName,
      date: i.date
    })
    state.myPayoutsTableItems = lPayouts;
  }
}

export const actions = {
  buildTableHeaders({commit}, fields) {
    let inProgressHeaders=[]
    let myPayoutsHeaders=[]

    fields.allFields["SPIFF_PAYOUTS"].forEach(f=>{
      if(f.fieldName.substring(0,2) === 'in') {
        inProgressHeaders.push({
          text: f.displayText,
          value: f.fieldName.split('_')[1],
          isVisibleToUser: fields.fieldsWithVisibilitySettings.columnSettings ?
            fields.fieldsWithVisibilitySettings.columnSettings.find(field=>field.columnProperty === f.fieldName).isVisibleForUser
          : true
          }
        )
      }
      else if(f.fieldName.substring(0,2) === 'my') {
        myPayoutsHeaders.push({
          text: f.displayText,
          value: f.fieldName.split('_')[1],
          isVisibleToUser: fields.fieldsWithVisibilitySettings.columnSettings ?
            fields.fieldsWithVisibilitySettings.columnSettings.find(field=>field.columnProperty === f.fieldName).isVisibleForUser
            : true
        });
      }
    })
    commit('setTableHeaders',[inProgressHeaders, myPayoutsHeaders])
    return true;
  },
  fetchAllPayouts({commit}, userId) {
    return this.$axios.$get(`spiffPayouts/${userId}`).then(payouts=>{
      commit('setInProgressItems', payouts.value.filter(p=>p.statusCode === "WAITING_FOR_APPROVAL"))
      commit('setMyPayoutsTableItems', payouts.value.filter(p=>p.statusCode === "APPROVED"))
    })
  }
}
