export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyNuxtDynamic: () => import('..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyLogo: () => import('..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
      LazyVuetifyLogo: () => import('..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => c.default || c),
      LazyCommonColumnUserSettingDialog: () => import('..\\components\\common\\column-user-setting-dialog.vue' /* webpackChunkName: "components/common-column-user-setting-dialog" */).then(c => c.default || c),
      LazyCommonConfirm: () => import('..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => c.default || c),
      LazyCommonCustomNumberInput: () => import('..\\components\\common\\custom-number-input.vue' /* webpackChunkName: "components/common-custom-number-input" */).then(c => c.default || c),
      LazyCommonExpandingFilterButton: () => import('..\\components\\common\\expanding-filter-button.vue' /* webpackChunkName: "components/common-expanding-filter-button" */).then(c => c.default || c),
      LazyCommonForgotPasswordDialog: () => import('..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => c.default || c),
      LazyCommonInfoBox: () => import('..\\components\\common\\info-box.vue' /* webpackChunkName: "components/common-info-box" */).then(c => c.default || c),
      LazyCommonLeftMenu: () => import('..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => c.default || c),
      LazyCommonLoginCard: () => import('..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => c.default || c),
      LazyCommonNavigationBars: () => import('..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => c.default || c),
      LazyCommonPrimaryButton: () => import('..\\components\\common\\primary-button.vue' /* webpackChunkName: "components/common-primary-button" */).then(c => c.default || c),
      LazyCommonRegisterCard: () => import('..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => c.default || c),
      LazyCommonSecondaryButton: () => import('..\\components\\common\\secondary-button.vue' /* webpackChunkName: "components/common-secondary-button" */).then(c => c.default || c),
      LazyCommonSwitchUser: () => import('..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => c.default || c),
      LazyCmsComponentAdd: () => import('..\\components\\cms\\component-add.vue' /* webpackChunkName: "components/cms-component-add" */).then(c => c.default || c),
      LazyCmsComponentFieldEditDialog: () => import('..\\components\\cms\\component-field-edit-dialog.vue' /* webpackChunkName: "components/cms-component-field-edit-dialog" */).then(c => c.default || c),
      LazyCmsContentAdd: () => import('..\\components\\cms\\content-add.vue' /* webpackChunkName: "components/cms-content-add" */).then(c => c.default || c),
      LazyCmsContentField: () => import('..\\components\\cms\\content-field.vue' /* webpackChunkName: "components/cms-content-field" */).then(c => c.default || c),
      LazyCmsFileInfo: () => import('..\\components\\cms\\file-info.vue' /* webpackChunkName: "components/cms-file-info" */).then(c => c.default || c),
      LazyCmsImageCropper: () => import('..\\components\\cms\\image-cropper.vue' /* webpackChunkName: "components/cms-image-cropper" */).then(c => c.default || c),
      LazyCmsSectionField: () => import('..\\components\\cms\\section-field.vue' /* webpackChunkName: "components/cms-section-field" */).then(c => c.default || c),
      LazyConfigurationFieldRows: () => import('..\\components\\configuration\\field-rows.vue' /* webpackChunkName: "components/configuration-field-rows" */).then(c => c.default || c),
      LazyConfigurationFieldSettingDialog: () => import('..\\components\\configuration\\field-setting-dialog.vue' /* webpackChunkName: "components/configuration-field-setting-dialog" */).then(c => c.default || c),
      LazyConfigurationGroupSettingsDialog: () => import('..\\components\\configuration\\group-settings-dialog.vue' /* webpackChunkName: "components/configuration-group-settings-dialog" */).then(c => c.default || c),
      LazyConfigurationModules: () => import('..\\components\\configuration\\modules.vue' /* webpackChunkName: "components/configuration-modules" */).then(c => c.default || c),
      LazyConfigurationRoleRows: () => import('..\\components\\configuration\\role-rows.vue' /* webpackChunkName: "components/configuration-role-rows" */).then(c => c.default || c),
      LazyConfigurationRoleSettingDialog: () => import('..\\components\\configuration\\role-setting-dialog.vue' /* webpackChunkName: "components/configuration-role-setting-dialog" */).then(c => c.default || c),
      LazySharedAttributeFields: () => import('..\\components\\shared\\attribute-fields.vue' /* webpackChunkName: "components/shared-attribute-fields" */).then(c => c.default || c),
      LazySharedCarouselTest: () => import('..\\components\\shared\\carousel-test.vue' /* webpackChunkName: "components/shared-carousel-test" */).then(c => c.default || c),
      LazySharedCloseBtn: () => import('..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => c.default || c),
      LazySharedCustomButton: () => import('..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => c.default || c),
      LazySharedCustomCombo: () => import('..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => c.default || c),
      LazySharedCustomSwitch: () => import('..\\components\\shared\\custom-switch.vue' /* webpackChunkName: "components/shared-custom-switch" */).then(c => c.default || c),
      LazySharedDarkButton: () => import('..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => c.default || c),
      LazySharedFilterButton: () => import('..\\components\\shared\\filter-button.vue' /* webpackChunkName: "components/shared-filter-button" */).then(c => c.default || c),
      LazySharedFilterCard: () => import('..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => c.default || c),
      LazySharedGallery: () => import('..\\components\\shared\\gallery.vue' /* webpackChunkName: "components/shared-gallery" */).then(c => c.default || c),
      LazySharedImageCombo: () => import('..\\components\\shared\\image-combo.vue' /* webpackChunkName: "components/shared-image-combo" */).then(c => c.default || c),
      LazySharedImageUploadCombo: () => import('..\\components\\shared\\image-upload-combo.vue' /* webpackChunkName: "components/shared-image-upload-combo" */).then(c => c.default || c),
      LazySharedImageUploaderField: () => import('..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => c.default || c),
      LazySharedImageUploader: () => import('..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => c.default || c),
      LazySharedInfoFields: () => import('..\\components\\shared\\info-fields.vue' /* webpackChunkName: "components/shared-info-fields" */).then(c => c.default || c),
      LazySharedLabeledControlFields: () => import('..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => c.default || c),
      LazySharedLabeledControlLayout: () => import('..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => c.default || c),
      LazySharedMemberSelectDialog: () => import('..\\components\\shared\\member-select-dialog.vue' /* webpackChunkName: "components/shared-member-select-dialog" */).then(c => c.default || c),
      LazySharedMenuDropdownButton: () => import('..\\components\\shared\\menu-dropdown-button.vue' /* webpackChunkName: "components/shared-menu-dropdown-button" */).then(c => c.default || c),
      LazySharedNameValueField: () => import('..\\components\\shared\\name-value-field.vue' /* webpackChunkName: "components/shared-name-value-field" */).then(c => c.default || c),
      LazySharedPrimarySubheader: () => import('..\\components\\shared\\primary-subheader.vue' /* webpackChunkName: "components/shared-primary-subheader" */).then(c => c.default || c),
      LazySharedPrimaryTitle: () => import('..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => c.default || c),
      LazySharedRadioCombo: () => import('..\\components\\shared\\radio-combo.vue' /* webpackChunkName: "components/shared-radio-combo" */).then(c => c.default || c),
      LazySharedSecondaryButton: () => import('..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => c.default || c),
      LazySharedSimpleInfoFields: () => import('..\\components\\shared\\simple-info-fields.vue' /* webpackChunkName: "components/shared-simple-info-fields" */).then(c => c.default || c),
      LazySharedSimpleNameValueField: () => import('..\\components\\shared\\simple-name-value-field.vue' /* webpackChunkName: "components/shared-simple-name-value-field" */).then(c => c.default || c),
      LazySalesFilterCardDatePicker: () => import('..\\components\\sales\\filter-card-date-picker.vue' /* webpackChunkName: "components/sales-filter-card-date-picker" */).then(c => c.default || c),
      LazySalesViewReceiptDialog: () => import('..\\components\\sales\\view-receipt-dialog.vue' /* webpackChunkName: "components/sales-view-receipt-dialog" */).then(c => c.default || c),
      LazySpiffplansCustomTableWithHeader: () => import('..\\components\\spiffplans\\custom-table-with-header.vue' /* webpackChunkName: "components/spiffplans-custom-table-with-header" */).then(c => c.default || c),
      LazySpiffplansItemsSoldDialog: () => import('..\\components\\spiffplans\\items-sold-dialog.vue' /* webpackChunkName: "components/spiffplans-items-sold-dialog" */).then(c => c.default || c),
      LazySpiffplansReceiptCamera: () => import('..\\components\\spiffplans\\receipt-camera.vue' /* webpackChunkName: "components/spiffplans-receipt-camera" */).then(c => c.default || c),
      LazySpiffplansSaleReceiptForm: () => import('..\\components\\spiffplans\\sale-receipt-form.vue' /* webpackChunkName: "components/spiffplans-sale-receipt-form" */).then(c => c.default || c),
      LazyCommonAdminSettingsAdmColumnsAutoComplete: () => import('..\\components\\common\\admin-settings\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/common-admin-settings-adm-columns-auto-complete" */).then(c => c.default || c),
      LazyCommonAdminSettingsGlobalSettings: () => import('..\\components\\common\\admin-settings\\global-settings.vue' /* webpackChunkName: "components/common-admin-settings-global-settings" */).then(c => c.default || c),
      LazyCommonMyProfileAddCardFields: () => import('..\\components\\common\\my-profile\\add-card-fields.vue' /* webpackChunkName: "components/common-my-profile-add-card-fields" */).then(c => c.default || c),
      LazyCommonMyProfileCreditCardInfoCard: () => import('..\\components\\common\\my-profile\\credit-card-info-card.vue' /* webpackChunkName: "components/common-my-profile-credit-card-info-card" */).then(c => c.default || c),
      LazyCommonMyProfileEditFormCard: () => import('..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => c.default || c),
      LazyCommonMyProfileGeneralInfoCard: () => import('..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => c.default || c),
      LazyCommonMyProfilePasswordChangeCard: () => import('..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => c.default || c)
  }

  const prefixes = ["frontend","nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
