import {formActions} from "@/enums/formActions";
import {frontends} from "@/enums/frontends";

const initState = () => ({
  components: [],
  componentsBeforeFiltering: []
})

export const state = initState

export const getters = {
  getComponents: (state) => (searchStr) => {
    const regexp = new RegExp(searchStr, 'i')
    if(state.components.length) {
      return state.components.filter(
        (x) => regexp.test(x.name) || regexp.test(x.code)
      )
    }
    else {
      return []
    }
  },
}

export const mutations = {
  setComponents(state, {components}) {
    state.components = components
    state.componentsBeforeFiltering = components
  },
  filterComponents(state, filters) {
    if (filters.filterType === 'Name') {
      state.components = state.componentsBeforeFiltering.filter(content=> {
         return filters.items.find(pContent=>pContent.id===content.id);
      })
    }
    else {
      if(filters.items.length > 1) {
        state.components = state.componentsBeforeFiltering.filter(comp=>comp.nestable && comp.contentType)
      }
      else {
        if(filters.items[0].name === 'Nestable') {
          state.components = state.componentsBeforeFiltering.filter(comp=>comp.nestable)
        }
        else {
          state.components = state.componentsBeforeFiltering.filter(comp=>comp.contentType)
        }
      }
    }
  }
}

export const actions = {
  fetchComponents({commit, state}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const components = await this.$axios.$get(`/manage/components/forFrontend/${frontends.SPIFF}`)
          commit('setComponents', {components})
          resolve({components})
        },
        formActions.Load,
        'components'
      )
    })
  },

  fetchComponentById({commit}, {componentId}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const component = await this.$axios.$get(
            `/manage/components/${componentId}`
          )
          resolve({component})
        },
        formActions.Load,
        'component'
      )
    })
  },

  fetchComponentFieldsById({commit}, {componentId}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const fields = await this.$axios.$get(
            `/manage/components/${componentId}/fields`
          )
          resolve({fields})
        },
        formActions.Load,
        'fields'
      )
    })
  },

  saveComponent({commit, dispatch}, {component, value}) {
    let result = new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          await this.$axios.$post(`/manage/components`, component)
          this.$toast.success(
            this.$i18n.t('saved_successfully').replace('{name}', component.name)
          )
          resolve()
        },
        formActions.Save,
        component.name
      )
    })
    return result
  },

  removeFieldsFromComponent({commit, dispatch}, {component, value}) {
    let result = new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          await this.$axios.$post(`/manage/components`, component)
          resolve()
        },
      )
    })
    return result
  },

  async deleteComponentById({commit, state}, {comp}) {
    let result = new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          await this.$axios.$delete(`/manage/components/${comp.id}`)
          this.$toast.success(
            this.$i18n.t('deleted_successfully').replace('{name}', comp.name)
          )
          resolve()
        },
        formActions.Delete,
        comp.name
      )
    })
    return result
  },

  filterStateComponents({commit}, filters) {
    commit('filterComponents', filters)
  }

}
